uniform vec3 uColor;
uniform vec3 uWireframeColor;
uniform float uWireframeThickness;

varying vec3 vBarycentric;

float getEdgeFactor(vec3 barycentric, float width) {
    vec3 d = fwidth(barycentric);
    vec3 a3 = smoothstep(vec3(0.0), d * width, barycentric);
    return min(min(a3.x, a3.y), a3.z);
}

void main() {
    // Improved anti-aliasing for the wireframe
    float edgeFactor = getEdgeFactor(vBarycentric, uWireframeThickness);

    // Mix the solid color with the wireframe color
    vec3 finalColor = mix(uWireframeColor, uColor, edgeFactor);

    gl_FragColor = vec4(finalColor, 1.0);
}