// SceneController.js

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { loadOBJ } from './utils/commonIO.js'
import { ShaderManager } from './shaders/ShaderManager.js'

const AMBIENT_LIGHT_COLOR = 0xffffff
const AMBIENT_LIGHT_INTENSITY = 2.0
const CAMERA_POSITION = new THREE.Vector3(0, 3, 6)

const clock = new THREE.Clock()
const rotate = false

export class SceneController {
  #canvas
  #renderer
  #camera
  #controls
  #scene
  #mesh
  #sizes = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  #shaderManager
  constructor () {
    this.#canvas = document.querySelector('.scene')
    this.#shaderManager = new ShaderManager()

    this.init()
  }

  async init () {
    // Scene
    this.#scene = new THREE.Scene()

    // Load models
    await this.loadModels()

    // Lights
    this.setLights()

    // Camera
    this.setCamera()

    // Renderer
    this.setRenderer()

    // Orbit controls
    this.setOrbitControls()

    // Set up shader selector
    this.setShaderSelector()

    // Set up events
    this.events()

    // Update
    this.update()

    // Update shader
    this.updateShader('hologram')
  }

  async loadModels () {
    try {
      this.#mesh = await loadOBJ('models/mesh.obj')
      this.#scene.add(this.#mesh)
    } catch (error) {
      console.error('Error loading obj model:', error)
    }
  }

  setLights () {
    const ambientLight = new THREE.AmbientLight(AMBIENT_LIGHT_COLOR, AMBIENT_LIGHT_INTENSITY)
    this.#scene.add(ambientLight)
  }

  setCamera () {
    this.#camera = new THREE.PerspectiveCamera(25, this.#sizes.width / this.#sizes.height, 0.1, 100)
    this.#camera.position.set(CAMERA_POSITION.x, CAMERA_POSITION.y, CAMERA_POSITION.z)
  }

  setRenderer () {
    if (this.#canvas instanceof HTMLCanvasElement) {
      this.#renderer = new THREE.WebGLRenderer({
        canvas: this.#canvas,
        antialias: true
      })
      this.#renderer.setSize(window.innerWidth, window.innerHeight)
    } else {
      // Handle the case where canvas was not found or not an HTMLCanvasElement
      console.error('Canvas element not found or invalid:', this.#canvas)
    }
  }

  setOrbitControls () {
    this.#controls = new OrbitControls(this.#camera, this.#renderer.domElement)
    this.#controls.enableDamping = true
  }

  events () {
    // window resize
    window.addEventListener('resize', this.onWindowResize.bind(this), false)
    // shader selector
    const shaderSelector = document.getElementById('shaderSelector')
    if (shaderSelector) {
      shaderSelector.addEventListener('change', (event) => {
        if (event.target instanceof HTMLSelectElement && event.target.value) {
          this.updateShader(event.target.value)
        } else {
          console.warn('Invalid shader selection')
        }
      })
    } else {
      console.warn('Shader selector element not found')
    }
  }

  /**
    * Renders the scene and updates controls (if available) in a loop using requestAnimationFrame.
    */
  update () {
    const elapsedTime = clock.getElapsedTime()

    // Update global uTime
    this.#shaderManager.updateTime(elapsedTime)

    // Rotate
    if (rotate) this.applyRotation(elapsedTime)

    // Update controls
    if (this.#controls) this.#controls.update()

    this.#renderer.render(this.#scene, this.#camera)
    window.requestAnimationFrame(this.update.bind(this))
  }

  applyRotation (elapsedTime) {
    // Define the range of rotation in radians
    const maxRotationY = Math.PI / 8 // 22.5 degrees in radians
    const minRotationY = -Math.PI / 8 // -22.5 degrees in radians

    // Calculate the rotation angle using a sine function to oscillate between the min and max values
    const range = maxRotationY - minRotationY
    const rotationY = Math.sin(elapsedTime * 0.1) * range

    // Apply the rotation
    this.#mesh.rotation.y = rotationY
  }

  /**
    * Handles the window resize event.
    * Updates the width and height of the sizes object, recalculates the aspect ratio of the camera,
    * updates the camera's projection matrix, and resizes the renderer to match the new window dimensions.
    */
  onWindowResize () {
    this.#sizes.width = window.innerWidth
    this.#sizes.height = window.innerHeight
    this.#camera.aspect = this.#sizes.width / this.#sizes.height
    this.#camera.updateProjectionMatrix()
    this.#renderer.setSize(this.#sizes.width, this.#sizes.height)
  }

  setShaderSelector () {
    const menu = document.querySelector('.selector-menu')
    const selected = menu.querySelector('.selector-menu__selected')
    const options = menu.querySelector('.selector-menu__options')

    // Toggle dropdown on click
    selected.addEventListener('click', () => {
      menu.classList.toggle('open')
    })

    // Handle option selection
    options.querySelectorAll('.selector-menu__option').forEach(option => {
      option.addEventListener('click', () => {
        const value = option.getAttribute('data-value')
        selected.textContent = option.textContent
        menu.classList.remove('open')
        this.updateShader(value)
      })
    })

    // Close dropdown when clicking outside
    document.addEventListener('click', (e) => {
      if (!menu.contains(e.target)) {
        menu.classList.remove('open')
      }
    })
  }

  updateShader (shaderKey) {
    const newMaterial = this.#shaderManager.getMaterial(shaderKey)
    if (newMaterial) {
      this.#mesh.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = newMaterial
        }
      })
    } else {
      console.error(`Material for shader ${shaderKey} not found`)
    }
  }
}
