import * as THREE from 'three'
import hologramVertexShader from './hologram/vertex.glsl'
import hologramFragmentShader from './hologram/fragment.glsl'
import normalsVertexShader from './normals/vertex.glsl'
import normalsFragmentShader from './normals/fragment.glsl'
import wireframeVertexShader from './wireframe/vertex.glsl'
import wireframeFragmentShader from './wireframe/fragment.glsl'
import toonVertexShader from './toon/vertex.glsl'
import toonFragmentShader from './toon/fragment.glsl'

const HOLOGRAM_COLOR = 0x0EA8A6
const WIREFRAME_BASE_COLOR = 0x333333
const WIREFRAME_LINE_COLOR = 0xffffff
const WIREFRAME_THICKNESS = 0.8
const TOON_COLOR = 0x0EA8A6
const TOON_LIGHT_POSITION = new THREE.Vector3(5, 5, 5)

export class ShaderManager {
  constructor () {
    this.shaders = {
      hologram: this.createShaderMaterial('hologram', {
        vertexShader: hologramVertexShader,
        fragmentShader: hologramFragmentShader,
        uniforms: {
          uColor: new THREE.Uniform(new THREE.Color(HOLOGRAM_COLOR))
        },
        transparent: true,
        side: THREE.DoubleSide,
        depthWrite: false,
        blending: THREE.AdditiveBlending
      }),
      normals: this.createShaderMaterial('normals', {
        vertexShader: normalsVertexShader,
        fragmentShader: normalsFragmentShader,
        uniforms: {},
        transparent: false,
        side: THREE.DoubleSide,
        depthWrite: true,
        blending: THREE.NormalBlending
      }),
      wireframe: this.createShaderMaterial('wireframe', {
        vertexShader: wireframeVertexShader,
        fragmentShader: wireframeFragmentShader,
        uniforms: {
          uColor: new THREE.Uniform(new THREE.Color(WIREFRAME_BASE_COLOR)),
          uWireframeColor: new THREE.Uniform(new THREE.Color(WIREFRAME_LINE_COLOR)),
          uWireframeThickness: { value: WIREFRAME_THICKNESS }
        },
        transparent: false,
        side: THREE.DoubleSide,
        depthWrite: true,
        blending: THREE.NormalBlending
      }),
      toon: this.createShaderMaterial('toon', {
        vertexShader: toonVertexShader,
        fragmentShader: toonFragmentShader,
        uniforms: {
          uColor: new THREE.Uniform(new THREE.Color(TOON_COLOR)),
          uLightPosition: new THREE.Uniform(TOON_LIGHT_POSITION)
        },
        transparent: false,
        side: THREE.DoubleSide,
        depthWrite: true,
        blending: THREE.NormalBlending
      })
    }
  }

  createShaderMaterial (name, config) {
    const material = new THREE.ShaderMaterial({
      vertexShader: config.vertexShader,
      fragmentShader: config.fragmentShader,
      uniforms: {
        uTime: { value: 0 },
        ...config.uniforms
      },
      transparent: config.transparent,
      side: config.side,
      depthWrite: config.depthWrite,
      blending: config.blending
    })

    if (name === 'wireframe') {
      material.extensions = {
        derivatives: true
      }
    }

    return material
  }

  getMaterial (shaderName) {
    return this.shaders[shaderName]
  }

  updateTime (time) {
    Object.values(this.shaders).forEach(material => {
      if (material.uniforms.uTime) {
        material.uniforms.uTime.value = time
      }
    })
  }
}
