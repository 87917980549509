import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import * as THREE from 'three'

const objLoader = new OBJLoader()

function computeBarycentric (geometry) {
  const positionAttribute = geometry.getAttribute('position')
  const vertexCount = positionAttribute.count
  const barycentric = new Float32Array(vertexCount * 3)

  for (let i = 0; i < vertexCount; i += 3) {
    barycentric[i * 3] = 1
    barycentric[i * 3 + 1] = 0
    barycentric[i * 3 + 2] = 0

    barycentric[(i + 1) * 3] = 0
    barycentric[(i + 1) * 3 + 1] = 1
    barycentric[(i + 1) * 3 + 2] = 0

    barycentric[(i + 2) * 3] = 0
    barycentric[(i + 2) * 3 + 1] = 0
    barycentric[(i + 2) * 3 + 2] = 1
  }

  geometry.setAttribute('barycentric', new THREE.BufferAttribute(barycentric, 3))
}

export function loadOBJ (url) {
  return new Promise((resolve, reject) => {
    objLoader.load(
      url,
      (object) => {
        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            // Ensure the geometry is a BufferGeometry
            if (!(child.geometry instanceof THREE.BufferGeometry)) {
              child.geometry = new THREE.BufferGeometry().fromGeometry(child.geometry)
            }

            // Compute and add barycentric coordinates
            computeBarycentric(child.geometry)
          }
        })
        resolve(object)
      },
      undefined,
      (error) => {
        reject(error)
      }
    )
  })
}
