// Normal fragment shader: applies basic lighting based on vertex normals

// Varyings
varying vec3 vNormal;

void main() {
    // Normalize the normal vector
    vec3 normal = normalize(vNormal);

    // Map the normal vector to RGB color space
    // We add 1.0 and multiply by 0.5 to map from [-1, 1] to [0, 1] range
    vec3 color = 0.5 * (normal + 1.0);

    // Set the fragment color
    gl_FragColor = vec4(color, 1.0);
}