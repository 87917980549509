uniform vec3 uColor;
uniform vec3 uLightPosition;

varying vec3 vNormal;
varying vec3 vViewPosition;

void main() {
    vec3 normal = normalize(vNormal);
    vec3 lightDir = normalize(uLightPosition - vViewPosition);

    // Calculate diffuse lighting
    float diff = max(dot(normal, lightDir), 0.0);

    // Quantize the diffuse to create cel-shading effect
    if (diff > 0.95) diff = 1.0;
    else if (diff > 0.5) diff = 0.6;
    else if (diff > 0.25) diff = 0.4;
    else diff = 0.2;

    // Calculate rim lighting
    vec3 viewDir = normalize(vViewPosition);
    float rimDot = 1.0 - max(dot(viewDir, normal), 0.0);
    float rimIntensity = smoothstep(0.716, 0.72, rimDot);

    // Combine diffuse and rim lighting
    vec3 finalColor = uColor * (diff + rimIntensity * 0.5);

    gl_FragColor = vec4(finalColor, 1.0);
}
